<template>
  <div class="getVoucher">
    <div class="header">
      <div class="logo">
        <img src="@/assets/UNIQGIFT_logo_no_tagline_white_150x29.png" alt="Uniqgift Logo"/>
      </div>
    </div>
    <div class="content">
      <form @submit.prevent>
        <label>Enter Your Voucher Code:</label>
        <input type="text" placeholder="" v-model="code" required>
        <div class="submitButton">
          <el-button type="primary" color="#fffff" @click="handleSubmit">View Voucher</el-button>
          <div v-if="isPending">Searching for your voucher...</div>
          <div v-if="gotResult">Opening your voucher...</div>
        </div>
      </form>
      <div v-if="error" class="error"><b>{{ error }}</b></div>
    </div>
    <div class="footer">
      <a href="https://uniqgift.com/about-us/">About Us</a>
      |
      <a href="https://uniqgiftsupport.freshdesk.com/support/home">Help</a>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
// We need the `functions` function with the correct firebase config info
import { functions } from "@/firebase/config"
// Needed to call Firebase callable functions
import { httpsCallable } from "firebase/functions";

export default {
  name: "Home",
  components: {},
  setup() {
    // The code is empty at first
    const code = ref('');

    // Initially the Voucher URL is empty at first
    const voucherGuid = ref('');

    // Track if we are currently searching for a Voucher
    const isPending = ref(false);

    // Track if we have results from the function
    const gotResult = ref(false);

    // Create a const to return errors if we have any
    const error = ref(null);

    // Function when we submit the eCode
    const handleSubmit = async () => {
      // Refresh the value of the error if we click again
      error.value = null;

      // We log the result of the user input
      console.log("The submitted code is: ", code.value);

      // Checks on the eCode we got
      if (!(typeof code.value === "string") ||
        code.value.length === 0 ) {
        // Updating the Error message.
        error.value = "Please enter a valid eCode"

        // Stop here
        return;
      }

      // We are starting to search:
      isPending.value = true;

      // Try to get the result form the function
      try {
        // Prepare the function we will use
        const getVoucherUrl = httpsCallable(functions, "checkCode");
        
        // Call the function using the eCode that was entered
        // wait (await) for it to reply
        const queryResult = await getVoucherUrl ({
          eCode: code.value,
        });

        // We update the value of Voucher GUID
        voucherGuid.value = await queryResult.data.voucherGuid;

        // We got a result:
        isPending.value = false;
        gotResult.value = true;

        // Log the data we get
        console.log("The data we get from the function: ", queryResult);

        // Log the data we get
        console.log("The voucher GUID: ", voucherGuid.value);

        // URL prefix
        const urlPrefix = ref(process.env.VUE_APP_URL_TO_VOUCHER);

        // We log the result of the user input
        console.log("The URL prefix is: ", urlPrefix.value);

        // Const for the URL to the voucher
        const voucherUrl = ref(urlPrefix.value + voucherGuid.value);

        // Log for debugging
        console.log("The url to the voucher is: ", voucherUrl.value);

        // We route the user to the Voucher
        window.location.href = voucherUrl.value;

      }
      // If there is an error - catch it
      catch (err) {
        // We got a result:
        isPending.value = false;

        // We update the value of the error const
        error.value = err.message

        // Log the error in the console
        console.log("The error for the returnCode function is: ", error.value);
      }  
    };

    return { code, error, handleSubmit, voucherGuid, isPending, gotResult };
  },
};
</script>

<style>
  .getVoucher {
    margin: auto;
    background-color: #0C2751;
    justify-content:center;
  }
  .header {
    height: 30vh;
    margin: 10px auto;
  }
  .content {
    height: 60vh;
  }
  .logo {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  form {
    max-width: 420px;
    font-size: 2em;
    margin: 10px auto;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
  }
  label {
    color: #fff;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    width: 200px
  }
  input {
    padding: 10px 6px;
    text-align: center;
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: #555;
  }
  .submitButton {
    padding: 20px 6px;
    display: block;
    text-align: center;
    height: 20vh;
  }
  .footer {
    height: 10vh;
    margin: 10px auto;
    text-transform: uppercase;
    font-size: 0.8em;
  }
  a {
    color: #fff;
  }
  .error {
    font-weight: bold;
    margin: 5px auto;
    color: #f56c6c;
  }
</style>